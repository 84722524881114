import React from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';

const ACCESS_TOKEN = localStorage.getItem('user-accessToken');

const PrivateRoute = () => {
  const { instDomain } = useParams();
  const isAuthenticated = !!ACCESS_TOKEN;

  return isAuthenticated ? <Outlet /> : <Navigate to={`${instDomain + '/loginUser'}`} />;
};

export default PrivateRoute;
